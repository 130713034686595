<template>
  <div id="backgroundColor">
    <meta name="theme-color" :content="user.backgroundColour" />

    <div id="backgroundColor1" class="hidden-md-and-up">
      <div>
        <v-img
          style="height: 100%; width: 100%; border-radius: 0px"
          :src="user.userPhotoURL"
          :lazy-src="user.userPhotoURL"
          ><v-btn
            small
            fab
            style="position: relative; top: 10px; left: 40%"
            @click="copyLink()"
          >
            <v-icon>mdi-content-copy</v-icon></v-btn
          ></v-img
        >
      </div>

      <div style="padding-top: 10px">
        <div class="userName">{{ user.userName }}</div>

        <div class="userAtName">
          {{ user.userAtName }}
        </div>
        <div id="backgroundAboutMe">
          <span class="userBio">
            {{ user.userBio }}
          </span>
        </div>
        <div style="margin: 10px">
          <v-row
            style="margin-left: 0px; margin-top: 0px"
            v-for="(link, index) in sortedList"
            v-bind:key="link.id"
          >
            <v-btn
              lass="text-right"
              text
              large
              style="margin-bottom: 20px"
              v-if="link.Active == true"
              @click="openUrl(link)"
            >
              <v-icon large style="left: -20px" :color="user.colourIconLink">
                {{ link.mdi }}
              </v-icon>

              <div class="linkText">
                {{ link.url }}
              </div>
            </v-btn>
          </v-row>
        </div>
      </div>
    </div>

    <div id="backgroundColor2" class="hidden-sm-and-down">
      <div>
        <v-row>
          <v-col cols="12" md="4" sm="0"></v-col>
          <v-col cols="12" md="4" sm="12" xs="12">
            <v-img
              style="padding-top: 0px; border-radius: 5px"
              :src="user.userPhotoURL"
              :lazy-src="user.userPhotoURL"
            ></v-img>
            <br />
          </v-col>
          <v-col cols="12" md="4" sm="0"></v-col
        ></v-row>
      </div>
      <div>
        <v-row>
          <v-col cols="12" md="4" sm="0"></v-col>
          <v-col cols="12" md="4" sm="12">
            <div class="userName">{{ user.userName }}</div>

            <div class="userAtName">
              {{ user.userAtName }}
            </div>
            <div id="backgroundAboutMe">
              <span class="userBio">
                {{ user.userBio }}
              </span>
            </div>

            <div style="margin: 10px">
              <v-row
                style="margin-left: 0px; margin-top: 0px"
                v-for="(link, index) in sortedList"
                v-bind:key="link.id"
              >
                <v-btn
                  lass="text-right"
                  text
                  large
                  style="margin-bottom: 20px"
                  v-if="link.Active == true"
                  @click="openUrl(link)"
                >
                  <v-icon
                    large
                    style="left: -20px"
                    :color="user.colourIconLink"
                  >
                    {{ link.mdi }}
                  </v-icon>

                  <div class="linkText">
                    {{ link.url }}
                  </div>
                </v-btn>
              </v-row>
            </div>
          </v-col>
          <v-col cols="12" md="4" sm="0"></v-col
        ></v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/main";
import firebase from "firebase/app";
import "firebase/storage";

export default {
  name: "App",

  data: () => ({
    user: "",
    userID: "",
    userAtName: "",
    name: "",
    bio: "",
    linkName: "",
    linkURL: "",
    dialog: false,
    editProfile: true,
    sortedList: [],
  }),

  methods: {
    copyLink() {
      const tmpTextField = document.createElement("textarea");
      tmpTextField.textContent =
        "https://ezlinking.net/" + this.$route.params.id;
      tmpTextField.setAttribute("style", "position:absolute; right:200%;");
      document.body.appendChild(tmpTextField);
      tmpTextField.select();
      tmpTextField.setSelectionRange(0, 99999); /*For mobile devices*/
      document.execCommand("copy");
      tmpTextField.remove();

      alert("Contact card link copied to your clipboard.");
    },
    openUrl(link) {
      if (link.mdi == "mdi-cellphone") {
        document.location.href = "tel:" + link.url;
      } else if (link.mdi == "mdi-phone") {
        document.location.href = "tel:" + link.url;
      } else if (link.mdi == "mdi-email") {
        window.location.href = "mailto:" + link.url;
      } else if (link.mdi != "mdi-phone" || link.mdi != "mdi-email") {
        window.open("https://" + link.url, "_blank");
      }

      var tempData = { link };

      db.collection("users")
        .doc(this.userID)
        .update({
          links: firebase.firestore.FieldValue.arrayRemove({
            id: link.id,
            name: link.name,
            url: link.url,
            views: link.views,
            Active: link.Active,
            mdi: link.mdi,
          }),
        })
        .then((docRef) => {
          //console.log("First Removed");
        })
        .catch((error) => {
          //console.error("Error adding document: ", error);
        });

      db.collection("users")
        .doc(this.userID)
        .update({
          links: firebase.firestore.FieldValue.arrayUnion({
            id: tempData.link.id,
            name: tempData.link.name,
            url: tempData.link.url,
            views: tempData.link.views + 1,
            Active: tempData.link.Active,
            mdi: tempData.link.mdi,
          }),
        })
        .then((docRef) => {
          //console.log("Document Updated");
        })
        .catch((error) => {
          //console.error("Error adding document: ", error);
        });
    },
    getData(user) {
      // console.log(user);
      db.collection("users")
        //.doc("F8bploUlTiPZ7QtwR9P1xfyvUB03")
        .doc(user)
        .onSnapshot((doc) => {
          //   var source = doc.metadata.hasPendingWrites ? "Local" : "Server";
          //    //console.log(source, " data: ", doc.data());

          this.user = doc.data();
          this.userAtName = doc.data().userAtName;
          this.userID = doc.id;
          this.sortedList = this.user.links.sort((a, b) => a.id - b.id);
        });
    },
  },
  computed: {},
  created() {
    // console.log(this.$route.params.id);

    db.collection("users")
      .where("userAtName", "==", this.$route.params.id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id);
          this.getData(doc.id);
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  },
};
</script>

<style scoped>
#backgroundColor {
  background-color: v-bind("user.backgroundColour");
  height: 110vh;
}

#backgroundColor1 {
  background-color: v-bind("user.backgroundColour");
}

#backgroundColor2 {
  background-color: v-bind("user.backgroundColour");
}
#backgroundAboutMe {
  background-color: v-bind("user.backgroundColourAboutMe");
  margin: 5px;
  padding: 10px;
  border-radius: 10px;
}

.link {
  text-align: center;
}

.linkText {
  margin-left: -20px;
  position: relative;
  top: -2px;
  font-size: 16px;
  font-weight: normal;
  color: v-bind("user.colourIconLink");
}
.backgroundColour {
  color: v-bind("user.backgroundColour");
}
.userName {
  color: v-bind("user.textColourName");
  font-size: 25px;
  font-weight: bold;
  margin-top: -10px;
  margin-left: 5px;
  margin-right: 5px;
}

.userAtName {
  color: v-bind("user.textColourUserName");
  font-size: 20px;
  margin-left: 5px;
  margin-right: 5px;
}
.userBio {
  color: v-bind("user.textColourInfo");
  font-size: 20px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.colourIconLink {
  color: v-bind("user.colourIconLink");
  font-size: 14px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}
</style>
