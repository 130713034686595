import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Profile from "../views/Profile.vue";
import User from "../views/User.vue";
import Contact from "../views/ContactUs.vue";
import SignUp from "../views/SignUp.vue";
import Login from "../views/Login.vue";
import AboutUs from "../views/AboutUs.vue";
import Admin from "../views/Admin.vue";
import store from "../store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        requiresGuest: true,
      },
    },
    {
      path: "/AboutUs",
      name: "AboutUs",
      component: AboutUs,
      meta: {
        requiresGuest: true,
      },
    },
    {
      path: "/SignUp",
      name: "SignUp",
      component: SignUp,
      meta: {
        requiresGuest: true,
      },
    },
    {
      path: "/Login",
      name: "Login",
      component: Login,
      meta: {
        requiresGuest: true,
      },
    },
    {
      path: "/ContactUs",
      name: "Contact",
      component: Contact,
      meta: {
        requiresGuest: true,
      },
    },
    {
      path: "/:id/Profile",
      name: "Profile",
      component: Profile,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/:id/Admin",
      name: "Admin",
      component: Admin,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/:id",
      name: "user",
      component: User,
      meta: {
        requiresGuest: true,
      },
    },
  ],
});

//

import firebase from "firebase";

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.isLoggedIn) {
      next({
        path: "/Login",
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.redirectIfLogged)) {
    if (store.state.isLoggedIn) {
      if (store.state.userID === firebase.auth().currentUser.uid) {
        next({
          path: "/",
        });
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
