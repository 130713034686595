<template>
  <v-app>
    <v-main style="width: 100%">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import firebase from "firebase";

export default {
  name: "App",

  data() {
    return {
      drawer: false,
    };
  },
  created() {
    if (this.$store.state.isLoggedIn == true) {
      if (this.$store.state.userID != firebase.auth().currentUser) {
        {
          firebase
            .auth()
            .signOut()
            .then(() => {
              {
                this.$store.commit("IsLoggedIn", false);
                this.$store.commit("UserAtName", "");
                this.$store.commit("UserID", "");
                this.$router.push("/Login");
              }
            });
        }
      }
    }
  },
  computed: {},
  methods: {},
};
</script>

<style>
.v-btn {
  text-transform: unset !important;
}
</style>
