<template>
  <v-container>
    <NavgationBar />
    <v-card flat class="text-center" tile>
      <v-row style="margin-left: 5px; margin-right: 5px">
        <v-col cols="12" sm="12" md="12">
          <h2>Administration</h2>
          <v-card
            flat
            class="text-left"
            style="padding-left: 5px; padding-right: 5px"
          >
            <h3>User Details</h3>
            <br />

            <v-row>
              <v-col cols="12" sm="7" md="5">
                <v-text-field
                  label="Your Name"
                  outlined
                  v-model="user.userName"
                  @change="editProfile = false"
                ></v-text-field>

                <v-text-field
                  label="Your Email"
                  outlined
                  v-model="user.email"
                  @change="editProfile = false"
                ></v-text-field>
                <v-text-field
                  label="Your UserName"
                  outlined
                  v-model="user.userAtName"
                  :rules="rules3"
                  @input="checkAtName(user.userAtName)"
                  @change="editProfile = false"
                ></v-text-field>
                <v-btn
                  color="primary"
                  :disabled="editProfile"
                  v-on:click.prevent="
                    updateProfile(user.userName, user.userAtName, user.email)
                  "
                  >Save Changes</v-btn
                ></v-col
              >

              <!-- <v-col>
                <div
                  style="
                    position: absolute;
                    bottom: 0;
                    margin-bottom: 5px;
                    margin-right: 20px;
                    right: 0;
                  "
                >
                  <v-btn color="primary" :disabled="editProfile"
                    >Save Changes</v-btn
                  >
                </div>
              </v-col> -->
            </v-row>
          </v-card>
        </v-col>
        <v-col>
          <v-card
            flat
            class="text-left"
            style="padding-left: 5px; padding-right: 5px"
          >
            <br />
            <v-btn color="primary" @click="passwordResetDialog = true"
              >Reset Password</v-btn
            >
            <v-btn
              color="error"
              style="margin-left: 20px"
              @click="deleteDialog = true"
              >Delete Account</v-btn
            >
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model="errorDialog1" max-width="290">
      <v-card>
        <v-card-title class="text-h5" style="color: #ed5151">
          Opps
        </v-card-title>

        <v-card-text>
          Entered User Name is not available. Try again.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="#ed5151" text @click="errorDialog1 = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5" style="color: black">
          Delete Account
        </v-card-title>

        <v-card-text>
          Are you sure you want to delete your account? Once delete, the account
          can not be recovered!
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            @click="deleteDialog = false"
            v-on:click.prevent="deleteAccount()"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="passwordResetDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5" style="color: black">
          Reset Password
        </v-card-title>

        <v-card-text>
          Click 'Confirm' to receive a password reset email in your registered
          email.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            @click="passwordResetDialog = false"
            v-on:click.prevent="resetPassword()"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import firebase from "firebase";
import NavgationBar from "../components/NavBar.vue";
import { db } from "@/main";

export default {
  name: "Admin",

  components: {
    NavgationBar,
  },
  data: () => ({
    user: "",
    userAtName: "",
    checkAtNameData: "",
    name: "",
    bio: "",
    linkName: "",
    linkURL: "",
    dialog: false,
    editProfile: true,
    errorDialog1: false,
    deleteDialog: false,
    passwordResetDialog: false,
    newPassword: "",

    rules3: [],
  }),

  methods: {
    resetPassword() {
      firebase
        .auth()
        .sendPasswordResetEmail(this.user.email)
        .then(() => {
          // Password reset email sent!
          // ..
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          // ..
        });
    },
    deleteAccount() {
      const user = firebase.auth().currentUser;

      db.collection("users")
        .doc(this.$store.state.userID)
        .delete()
        .then(() => {
          // console.log("Document successfully deleted!");
        })
        .catch((error) => {
          // console.error("Error removing document: ", error);
        });

      user
        .delete()
        .then(() => {
          // User deleted.
          {
            // console.log(doc.data().userAtName);
            this.$store.commit("IsLoggedIn", false);
            this.$store.commit("UserAtName", "");
            this.$store.commit("UserID", "");
            this.$router.push(this.$route.query.redirect || "/");
          }
        })
        .catch((error) => {
          // An error ocurred
          // ...
        });
    },
    updateProfile(userName, userAtName, userEmail) {
      if (this.errorAtName) {
        this.errorDialog1 = true;
      } else if (!this.errorAtName) {
        db.collection("users")
          .doc(this.userDocID)
          .update({
            userName: userName,
            userAtName: userAtName,
            email: userEmail,
          })
          .then((docRef) => {
            //console.log("Document Updated");
            this.editProfile = true;
          })
          .catch((error) => {
            //console.error("Error adding document: ", error);
          });
      }
    },
    checkAtName(input) {
      // //console.log("Checking", input);
      db.collection("users")
        .where("userAtName", "==", input)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            //   //console.log("doc.data()", doc.data().userAtName);
            this.checkAtNameData = doc.data().userAtName;
            // doc.data() is never undefined for query doc snapshots
            this.rules3 = ["Entered User Name is not available. Try again."];
            this.errorAtName = true;
            if (input === this.userAtName) {
              this.rules3 = [];
              this.errorAtName = false;
            }
          });
        })
        .catch((error) => {
          //console.log("Error getting documents: ", error);
        });
      if (this.checkAtNameData != input) {
        this.rules3 = [];
        this.errorAtName = false;
      }
    },
    getData(user) {
      // console.log(user);
      db.collection("users")
        //.doc("F8bploUlTiPZ7QtwR9P1xfyvUB03")
        .doc(user)
        .onSnapshot((doc) => {
          //   var source = doc.metadata.hasPendingWrites ? "Local" : "Server";
          //console.log(source, " data: ", doc.data());

          this.user = doc.data();
          this.userAtName = doc.data().userAtName;
        });
    },
  },
  computed: {
    UserID() {
      return this.$store.state.userID;
    },
    UserAtName() {
      return this.$store.state.userAtName;
    },
  },
  created() {
    // console.log(this.$route.params.id);

    db.collection("users")
      .where("userAtName", "==", this.$store.state.userAtName)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id);
          this.getData(doc.id);
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  },
};
</script>
