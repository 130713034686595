<template>
  <v-container>
    <NavgationBar />

    <div style="padding-left: 5px; padding-right: 5px" align="center">
      <br />
      <form action="login(email, password)">
        <v-card class="mx-auto" max-width="600">
          <v-card flat height="fit-content">
            <v-card-title class="headline justify-center">Login</v-card-title>
            <v-card-text>
              <v-text-field
                label="Email"
                outlined
                v-model="email"
              ></v-text-field>

              <v-text-field
                label="Password"
                type="Password"
                outlined
                v-model="password"
              ></v-text-field>
              <div style="text-align: left">
                <v-btn
                  text
                  small
                  right
                  class="text-none"
                  @click="resetPassword = true"
                  >Forgot my password</v-btn
                >
              </div>
            </v-card-text>
            <v-card-actions>
              <v-flex style="text-align: center">
                <v-btn
                  color="primary"
                  v-on:click.prevent="login(email, password)"
                  type="submit"
                  >Login</v-btn
                >
              </v-flex>
            </v-card-actions>
          </v-card>
          <br />
          <v-divider></v-divider>
          <v-card flat>
            <v-card-text>
              <v-flex style="text-align: center">
                Don't have an account yet?
                <v-btn small router-link to="/SignUp">Sign Up Now</v-btn>
              </v-flex>
            </v-card-text>
          </v-card>
        </v-card>
      </form>
    </div>

    <v-dialog v-model="resetPassword" max-width="320">
      <v-card flat max-width="320">
        <v-card-title>Reset Password</v-card-title>
        <v-card-text align="justify">
          Entered your email and if the email is registered in the system. A
          password reset link will be sent to your email.
          <br />
          <br />
          <v-text-field
            outlined
            v-model="resetEmail"
            label="Email"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-flex style="text-align: right">
            <v-btn
              color="primary"
              v-on:click.prevent="resetPasswordSend(resetEmail)"
            >
              Confirm
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import firebase from "firebase";
import { db } from "@/main";
import NavgationBar from "../components/NavBar.vue";
export default {
  name: "login",
  components: {
    NavgationBar,
  },
  data: function () {
    return {
      email: "",
      password: "",
      resetPassword: false,
      resetEmail: "",
    };
  },
  methods: {
    resetPasswordSend(resetEmail) {
      firebase
        .auth()
        .sendPasswordResetEmail(resetEmail)
        .then(() => {
          // Password reset email sent!
          // ..
          this.resetPassword = false;
          alert(
            "Please login into your email account and reset your password by clicking on the link received. Check the spam folder if the email does not appear in the inbox."
          );
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          // ..
        });
    },
    login(email, password) {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          (cred) => {
            if (cred.user.emailVerified) {
              db.collection("users")
                .doc(cred.user.uid)
                .get()
                .then((doc) => {
                  {
                    // console.log(doc.data().userAtName);
                    this.$store.commit("IsLoggedIn", true);
                    this.$store.commit("UserAtName", doc.data().userAtName);
                    this.$store.commit("UserID", doc.id);
                    this.$router.push(
                      this.$route.query.redirect ||
                        "/" + doc.data().userAtName + "/Profile"
                    );
                  }
                })
                .catch((err) => {
                  console.log("Error getting document", err);
                });
            } else {
              firebase
                .auth()
                .signOut()
                .then(() => {
                  // alert(
                  // "Please login into your mail and verify your email by clicking the link provided and login in again."
                  //);
                  this.$router.push("/Login");
                }),
                // //console.log(this.currentUser);
                cred.user
                  .sendEmailVerification()
                  .then(function () {
                    alert(
                      "Please login into your mail account and verify your email address by clicking the link provided."
                    );
                    // Email sent.
                  })
                  .catch(function (error) {
                    // An error happened.
                    console.log(error);
                  });
            }
          },
          (err) => {
            alert(err.message);
          }
        );
      event.preventDefault();
    },
  },
};
</script>
