import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    userAtName: "",
    userID: "",
  },
  getters: {},
  mutations: {
    IsLoggedIn(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
    UserAtName(state, userAtName) {
      state.userAtName = userAtName;
    },
    UserID(state, userID) {
      state.userID = userID;
    },
  },
  actions: {},
  modules: {},
  plugins: [new VuexPersistence().plugin],
});
