<template>
  <div class="divWindow">
    <meta name="theme-color" :content="windowBGColor" />
    <v-app-bar
      flat
      color="#FFFFFF00"
      style="padding-top: 20px; margin-left: 20px"
      class="d-flex justify-space-around"
    >
      <img src="../../public/E/1.png" width="60px" />
      <div id="navTitle1">EZ LINKING</div>
    </v-app-bar>

    <!-- <v-app-bar
      flat
      color="#FFFFFF00"
      style="padding-top: 20px; margin-left: 20px"
      class="hidden-md-and-up"
    >
      <img src="../../public/E/1.png" width="60px" />
      <div id="navTitle2">EZ LINKING</div>
    </v-app-bar> -->

    <div class="hidden-md-and-up" style="margin-top: 50px"></div>

    <div class="hidden-sm-and-down" style="margin-top: 60px"></div>

    <v-card flat>
      <v-row style="width: 100%; margin-left: 0px">
        <v-col class="bannerLeft hidden-sm-and-down" cols="12" sm="12" md="6">
          <div class="bannerText">
            One link for all others

            <v-btn rounded class="btnText3" v-on:click.prevent="goTO('/SignUp')"
              >Sign Up is Free</v-btn
            >
          </div>
        </v-col>
        <v-col class="bannerLeft2 hidden-md-and-up" cols="12" sm="12" md="6">
          <div class="bannerText2">
            One link for all others <br />
            <v-btn
              rounded
              class="btnText3"
              v-on:click.prevent="goTO('/SignUp')"
            >
              Sign Up is Free</v-btn
            >
          </div>
        </v-col>
        <v-col class="bannerRight hidden-sm-and-down" cols="12" sm="12" md="6">
          <v-img
            lazy-src="../../public/lpt2.png"
            style="height: 100%"
            src="../../public/lpt2.png"
          ></v-img>
        </v-col>
        <v-col class="bannerRight2 hidden-md-and-up" cols="12" sm="12" md="6">
          <v-img
            lazy-src="../../public/lpt2.png"
            style="height: 100%; margin-left: 30px; margin-top: -30px"
            src="../../public/lpt2.png"
          ></v-img>
        </v-col>
      </v-row>
    </v-card>

    <br />
    <p class="text-h4 text--primary">Why use EZLinking?</p>
    <v-container fluid>
      <v-row dense>
        <v-col>
          <v-card class="mx-auto" max-width="350" elevation="2" shaped outlined>
            <v-card-text>
              <div
                style="
                  text-align: left;
                  font-size: 20px;
                  color: black;
                  text-align: justify;
                "
              >
                <ul>
                  <li>It's free and easy to use.</li>
                </ul>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="mx-auto" max-width="350" elevation="2" shaped outlined>
            <v-card-text>
              <div
                style="
                  text-align: left;
                  font-size: 20px;
                  color: black;
                  text-align: justify;
                "
              >
                <ul>
                  <li>
                    It's a digital contact card with active profile links.
                  </li>
                </ul>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="mx-auto" max-width="350" elevation="2" shaped outlined>
            <v-card-text>
              <div
                style="
                  text-align: left;
                  font-size: 20px;
                  color: black;
                  text-align: justify;
                "
              >
                <ul>
                  <li>
                    The card colours are customisable, and you can post and
                    manage multiple social media and website links.
                  </li>
                </ul>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <div class="hidden-md-and-up" style="height: 90px"></div>
    <div class="hidden-sm-and-down"><br /><br /><br /></div>

    <v-footer
      color="#FFFFFF00"
      padless
      fixed
      style="padding: 20px; padding-bottom: 0px"
    >
      <v-row style="margin-bottom: 0px">
        <v-col cols="0" sm="2" md="3"></v-col>

        <v-col cols="4" sm="3" md="2" style="margin: 0px; margin-top: -20px">
          <v-btn
            elevation="2"
            width="100px"
            rounded
            :color="btnColor"
            v-on:click.prevent="goTO('/AboutUs')"
          >
            <span class="btnText">About Us</span></v-btn
          >
        </v-col>
        <v-col
          cols="4"
          sm="3"
          md="2"
          style="margin-right: 0px; margin-top: -20px"
          ><v-btn
            elevation="2"
            width="120px"
            rounded
            :color="btnColor"
            v-on:click.prevent="goTO('/ContactUs')"
          >
            <span class="btnText">Contact Us</span></v-btn
          ></v-col
        >
        <v-col cols="4" sm="3" md="2" style="margin: 0px; margin-top: -20px"
          ><v-btn
            elevation="2"
            width="90px"
            rounded
            :color="btnColor"
            v-on:click.prevent="goTO('/Login')"
          >
            <span class="btnText">Login</span></v-btn
          ></v-col
        >
        <v-col cols="0" sm="2" md="3"></v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "Home",

  metaInfo() {
    return {
      title: "EZ LINKING - Home",
      meta: [{ name: "title" }],
    };
  },

  components: {},
  data: () => ({
    logoColor: "#545454",
    btnColor2: "#415463",
    btnColor: "purple",
    btnTextColor3: "white",
    btnTextColor2: "white",
    btnTextColor: "white",
    windowBGColor: "white",
  }),
  methods: {
    goTO(link) {
      this.$router.push(link);
    },
  },
};
</script>
<style scope>
.bannerLeft {
  background-color: black;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bannerLeft2 {
  background-color: black;
  height: 35vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bannerRight {
  background-color: black;
  height: 60vh;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bannerRight2 {
  background-color: v-bind("windowBGColor");
  height: 45vh;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bannerText {
  color: white;
  font-size: 80px;
  font-family: "Prompt", sans-serif;
}
.bannerText2 {
  color: white;
  font-size: 40px;
  font-family: "Prompt", sans-serif;
}

#navTitle1 {
  color: black;
  font-family: "Vidaloka", serif;
  font-size: 40px;
  margin-left: 10px;
  font-weight: bold;
}
#navTitle2 {
  font-family: "Vidaloka", serif;
  font-size: 40px;
  font-weight: bold;
  color: black;
  margin-top: 0px;
  position: relative;
  left: 0px;
}
.btnText {
  color: v-bind("btnTextColor");
  font-weight: bold;
}
.btnText2 {
  color: v-bind("btnTextColor2");
  font-weight: 400;
}
.btnText3 {
  color: v-bind("btnTextColor3");
  font-weight: bold;
  font-family: "Inter", Helvetica, Arial, sans-serif;
}

.divWindow {
  background-color: v-bind("windowBGColor");
  width: 100vw;
  height: 100%;
}

#app {
  font-family: "Inter", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  background-color: white;
}
</style>

<!-- 

  logo colors:
  Black:black
  Grey: #545454
  Blue: #1d2b76
 -->
<!-- #242F42 -->
