import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import firebase from "firebase";

Vue.config.productionTip = false;
const firebaseConfig = {
  apiKey: "AIzaSyDdq_gu9WtcHf7htenyWAGEKzBjiq_ncPA",
  authDomain: "social-link-45434.firebaseapp.com",
  projectId: "social-link-45434",
  storageBucket: "social-link-45434.appspot.com",
  messagingSenderId: "119221614320",
  appId: "1:119221614320:web:ba9597ff4cb05d6bd97435",
  measurementId: "G-YGYJCQZ0P7",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const db = firebase.firestore();

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
