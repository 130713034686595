<template>
  <v-container> <NavgationBar /> <Dashboard /></v-container>
</template>

<script>
import Dashboard from "../components/Dashboard";
import NavgationBar from "../components/NavBar.vue";

export default {
  name: "Profile",

  components: {
    Dashboard,
    NavgationBar,
  },
};
</script>
