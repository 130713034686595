<template>
  <v-container>
    <NavgationBar />
    <div style="padding-left: 5px; padding-right: 5px" align="center">
      <v-img
        style="width: auto; height: auto; margin-left: 0px"
        src="../../public/contact.webp"
        lazy-src="../../public/contact.webp"
      />
      <div style="font-size: 8px">
        Photo by
        <a
          href="https://unsplash.com/@lunarts?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
          >Volodymyr Hryshchenko</a
        >
        on
        <a
          href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
          >Unsplash</a
        >
      </div>
      <br />
      <h3>Our Email address is : help.ezlinking@gmail.com</h3>

      <v-btn color="amber" large dark v-on:click.prevent="copyLink()"
        >Send Email</v-btn
      >
    </div>
  </v-container>
</template>

<script>
import NavgationBar from "../components/NavBar.vue";

export default {
  name: "ContactUs",
  metaInfo() {
    return {
      title: "EZ LINKING - Contact",
      meta: [{ name: "title" }],
    };
  },

  components: {
    NavgationBar,
  },
  data: function () {
    return {};
  },
  methods: {
    copyLink() {
      window.location.href = "mailto:help.ezlinking@gmail.com";
    },
  },
};
</script>
