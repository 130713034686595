<template>
  <v-container>
    <NavgationBar />
    <div style="padding-left: 5px; padding-right: 5px" align="center">
      <v-card
        flat
        style="
          background-color: white;
          height: 100vh;
          padding: 10px;
          width: auto;
        "
      >
        <v-card-title primary-title class="justify-center">
          <h3 class="headline black--text text-h4 mb-1">About Us</h3>
        </v-card-title>

        <v-card-text class="text-h5 black--text">
          Welcome to ezlinking.net, the web app that allows users to easily
          create and share their digital business card!
          <br />
          <br />

          Our mission is to help professionals and businesses of all sizes
          connect with each other in a fast, efficient, and digital way. With
          ezlinking.net, you can create a professional and visually appealing
          business card in just a few minutes, and share it with anyone via a
          unique URL.
          <br />
          <br />
          Our website is completely free to use, and we offer customizable
          design options to help you create the perfect business card for your
          needs. Whether you're a freelancer, small business owner, or corporate
          professional, ezlinking.net has something for everyone.
          <br />
          <br />
          Thank you for choosing ezlinking.net for your business card needs. We
          hope you find our platform useful and user-friendly, and we welcome
          any feedback or suggestions you may have.
          <br />
          <br />
        </v-card-text>

        <v-img
          style="width: auto; height: auto; margin-left: 0px"
          src="https://images.unsplash.com/photo-1455849318743-b2233052fcff?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2938&q=80"
          lazy-src="https://images.unsplash.com/photo-1455849318743-b2233052fcff?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2938&q=80"
        />
        <div style="font-size: 8px">
          Photo by
          <a
            href="https://unsplash.com/@goian?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
            >Ian Schneider</a
          >
          on
          <a
            href="https://unsplash.com/s/photos/about?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
            >Unsplash</a
          >
        </div>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import NavgationBar from "../components/NavBar.vue";

export default {
  name: "About",
  metaInfo() {
    return {
      title: "EZ LINKING - About",
      meta: [{ name: "title" }],
    };
  },

  components: {
    NavgationBar,
  },
};
</script>
