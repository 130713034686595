<template>
  <v-container>
    <NavgationBar />

    <div style="padding-left: 5px; padding-right: 5px" align="center">
      <br />
      <form action="continueSignup($event)">
        <v-card class="mx-auto" max-width="600">
          <v-card flat height="fit-content">
            <div>
              <v-card-text>
                <v-text-field
                  label="Your Email"
                  outlined
                  v-model="email"
                  :rules="rules4"
                  @input="checkEmail(email)"
                ></v-text-field>

                <v-text-field
                  name="name"
                  label="Your User Name"
                  placeholder="Eg. username"
                  outlined
                  v-model="userAtName"
                  :rules="rules3"
                  @input="checkAtName(userAtName)"
                ></v-text-field>
                <v-text-field
                  outlined
                  v-model="password"
                  :rules="[rules.required, rules.min]"
                  :type="show1 ? 'text' : 'password'"
                  label="Password"
                  hint="At least 8 characters"
                  @click:append="show1 = !show1"
                ></v-text-field>
                <v-text-field
                  outlined
                  v-model="password2"
                  :rules="[rules.required, passwordMatchCheck]"
                  :type="show2 ? 'text' : 'password'"
                  label="Confirm password"
                  @click:append="show2 = !show2"
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-flex>
                  By signing up, you are agreeing to the Terms and and
                  Conditions and Privacy Policy of this website.
                  <br />
                  <v-btn
                    small
                    text
                    @click="TermsDialog = true"
                    color="blue darken-2"
                    >Terms and Conditions and Privacy Policy</v-btn
                  >

                  <br />

                  <v-btn
                    style="margin-left: 2px"
                    dark
                    v-on:click.prevent="continueSignup($event)"
                    type="submit"
                    color="blue darken-2"
                    >Sign Up
                  </v-btn>
                </v-flex>
              </v-card-actions>
              <v-divider></v-divider>
              <v-card flat>
                <v-card-text>
                  <v-flex style="text-align: center">
                    Already have an account?
                    <v-btn small router-link to="/Login">Login Now</v-btn>
                  </v-flex>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-card>
      </form>
      <v-dialog v-model="TermsDialog" max-width="80%">
        <v-card>
          <v-card-title class="headline">Terms and Conditions</v-card-title>

          <v-card-text>
            <div style="text-align: justify">
              <h3></h3>

              <h4>Last updated: 11 August 2022</h4>
              <br />
              <h3>Introduction</h3>

              Welcome to EZLinking ! By using our website and/or using the
              services that are provided, you acknowledge that you have read,
              understood, and agree to be bound by our Terms and Conditions.
              These Terms and Conditions unconditionally extend and apply to all
              related applications, internet services, or website extensions. If
              you are not in agreement with all of these Terms and Conditions,
              you are prohibited from using this Website, and you may
              discontinue use immediately. EZLinking may modify this agreement
              at any time, and such modifications shall be effective immediately
              upon posting the modified agreement on the website.
              <br />
              <br />
              <h3>Agreement to Terms and Conditions</h3>

              EZLinking Terms And Conditions (these "Terms" or these "Terms and
              Conditions") contained in this Agreement shall govern your use of
              this Website and all its content (collectively referred to herein
              as this "Website"). These Terms outline the rules and regulations
              guiding the use of EZLinking located at www.ezlink.app. All
              materials/information/documents/services or all other entities
              (collectively referred to as content) that appear on the EZLinking
              shall be administered subject to these Terms and Conditions. These
              Terms and Conditions apply in full force and effect to your use of
              this Website, and the use of this Website constitutes an express
              agreement with all the terms and conditions contained herein in
              full. Do not continue to use this Website if you have any
              objection to any of the Terms and Conditions stated on this page.
              <br />
              <br />
              <h3>Definitions/Terminology</h3>

              The following definitions apply to these Terms and Conditions,
              Privacy Statement, Disclaimer Notice and all Agreements: "User",
              "Visitor," "Client," "Customer," "You" and "Your" refers to you,
              the person(s) that use this Website. "EZLinking ", "We", "Our" and
              "Us", refers to our Website/Company. "Party," "Parties," or "Us,"
              refers to both you and us. All terms refer to all considerations
              of EZLinking necessary to undertake support to you for the express
              purpose of meeting your User needs in respect of our services,
              under and subject to, the prevailing law of the state or country
              in which EZLinking operates (Fiji). Any use of these definitions
              or other glossary in the singular, plural, capitalization, and/or
              pronoun are interchangeable but refer to the same.
              <br />
              <br />
              <h3>Intellectual Property Rights</h3>

              Other than the content you own and opted to include on this
              Website, under these Terms, EZLinking and/or its licensors own and
              reserve all intellectual property rights of this Website. You are
              granted a limited license, subject to the restrictions entailed in
              these Terms and Conditions, for purposes of viewing this Website's
              content. Do not post content which may infringe an individual's
              intellectual property rights. Contact us if infringed. Do not post
              content on behalf of anyone unless given written permission to
              publish materials. <br />
              <br />
              <h3>Services</h3>

              The content of this Website is not intended for use or
              distribution to any person or entity in any jurisdiction,
              geographical location, or country/state where such use or
              distribution will be contrary to the laws and regulations or
              subject EZLinking to any form of registration, claims, demands,
              costs, liabilities, damages, or expenses. The Website is intended
              for users who are at least 18 years of age. If you are under the
              age of 18, you cannot use or register to use this Website or its
              services without parental permission or consent. By agreeing to
              these Terms and Conditions, you have the necessary legal capacity
              to comply and be bound by these Terms and Conditions.
              <br />
              <br />
              <h3>Acceptable Use</h3>

              You may use this Website as permitted by these Terms and
              Conditions and may not use this Website for any purpose other than
              for which EZLinking makes the Website and its services available.
              <br />
              <br />
              <h3>Cookies</h3>

              EZLinking employs the use of cookies. By accessing Our Website,
              You agree to use cookies in Agreement with our Cookie Policy. Our
              interactive Website's optimal functionality uses cookies to
              retrieve the User's information for each visit. Some of our
              affiliate partners may also use cookies.
              <br />
              <br />
              <h3>License</h3>

              Unless otherwise stated, EZLinking and/or its licensors own the
              intellectual property rights for all content on EZLinking. All
              intellectual property rights are reserved. You may access any
              Website content from EZLinking for your personal use subject to
              restrictions set in these terms and conditions. EZLinking hereby
              restricts you from all of the following:<br />
              Republishing any EZLinking content in any media; Reproducing,
              duplicating, or copying any EZLinking content ; Selling, renting,
              sublicensing, and/or otherwise commercializing any EZLinking
              content; Publicly performing and/or displaying any EZLinking
              content; Using this Website in a manner that is, or maybe,
              damaging and/or impacts user access to this Website; Using this
              Website contrary to the relevant rules, laws, and regulations of
              your country of residence, or in a manner that causes, or may
              cause, harm to the Website, or any person or business entity;
              Conducting data mining or any other similar activity concerning
              this Website, or while using this Website; and Using this Website
              to engage in any form of business advertising or marketing.
              Specific areas of this Website may be restricted from user access,
              and EZLinking may further extend such restrictions to the entire
              Website, at any time, and at its sole discretion. Any user
              identification, security key, or password you may have on this
              Website is confidential, and you are responsible for maintaining
              such information confidentiality.
              <br />
              <br />
              <h3>Linking and Hyperlinking Rights</h3>

              We reserve the right to file requests that you remove all links or
              any particular link created by you that redirect to our Website,
              and you approve to immediately remove such links to our Website
              upon request. We may amend the terms and conditions of these
              linking rights at any time. By continuously linking to our
              Website, you agree to be bound to and follow the terms of this
              linking policy. Feel free to contact us if you find any link on
              our Website that is offensive, and we may consider requests to
              remove such links. Still, we are not obligated to do so or respond
              to you directly or immediately.
              <br />
              <br />
              <h3>Hyperlinking to our Content</h3>

              Organizations such as search engines, government agencies, news
              organizations, and online directories may link to our Website
              without prior written approval. We may review other link requests
              from popular consumer and/or information specialists, charity
              organizations, internet portals, educational institutions, trade
              associations, and dot.com community sites. Any interested
              organization must inform and contact us for further information
              regarding our linking policy. However, such linking does not imply
              endorsement, sponsorship, partnership, or approval by us of any
              kind. No use of our logo or other design intellectual property
              will be allowed for linking, except a trademark license agreement.
              <br />
              <br />
              <h3>Link to third-party content</h3>

              This Website may contain links to websites or applications
              operated by third parties. Please know that we do not control any
              such third-party websites or applications or the third-party
              operator. EZLinking is not responsible for and does not endorse
              any third-party websites or applications or their availability or
              content. EZLinking accepts no responsibility for adverts contained
              on the Website. You agree that you do so at your own risk when you
              purchase any goods and/or services from any such third party. The
              advertiser, and not us, remains responsible for such goods and/or
              services, and if you have any questions or complaints about them,
              you should contact the advertiser.
              <br />
              <br />
              <h3>User Content</h3>

              In these Website Standard Terms and Conditions, "User Content"
              shall mean any audio, video, text, images, or other material or
              content you choose to display on this Website. Concerning user
              content, by displaying it, you grant EZLinking a non-exclusive,
              worldwide, irrevocable, royalty-free, sub-licensable license to
              use, reproduce, adapt, publish, translate and distribute it on any
              media. User Content must be your own and must not be infringing on
              any third party's rights. EZLinking reserves the right to remove
              any of your content from this Website at any time, without notice.
              EZLinking is permitted to monitor your activities on the Website
              and remove any user content considered inappropriate, offensive,
              contrary to applicable laws and regulations, or causes a breach of
              these Terms and Conditions.
              <br />
              <br />
              <h3>You warrant and represent that:</h3>

              You are entitled to upload/input/publicize content on our Website
              and have the necessary legal capacity, license or consent to do
              so; Your content does not infringe any intellectual property
              right, including without limitation to copyright, patent, or
              trademark of any third party; Your content is true, accurate,
              current, complete, and related to you and not a third party; Your
              content does not contain any libellous, defamatory, offensive,
              immoral, or otherwise illegal material which is an invasion of
              privacy; and The content will not be used to solicit or promote
              business or custom or present commercial activities or unlawful
              activity. You now grant EZLinking a non-exclusive license to use,
              reproduce, edit and authorize others approved by us to use,
              reproduce and edit any of your content in any form, format, or
              media.
              <br />
              <br />
              <h3>Privacy Policy</h3>

              By using this Website and its services, you may provide us with
              certain personal information. By using EZLinking or its services,
              you authorize us to use your information in any country or state
              that we operate in. We reserve the right to use such information
              to improve your user experience and facilitate mailing and
              traffic, and market analytics. By accessing this Website, specific
              information about the User, such as Internet protocol (IP)
              addresses, site navigation, user software, and the surfing time,
              along with other similar information, will be stored on EZLinking
              servers. Information about their identities, such as name,
              address, contact details, billing information, and other
              information stored on this Website, will strictly be used only for
              statistical purposes and will not be published for general access.
              EZLinking, however, assumes no responsibility for the security of
              this information.
              <br />
              <br />
              <h3>Disclaimers/Warranties/Limitation of Liabilities</h3>

              EZLinking Website is provided "as is," with all liabilities, and
              EZLinking makes no express or implied undertakings,
              representations, or warranties, of any kind related to this
              Website or the content contained on this Website. EZLinking does
              not make any endorsements, warranties, or representations about
              the accuracy, reliability, expertise, or completeness of any such
              content. You agree that reliance on any such content shall be at
              the User's risk. The EZLinking periodically changes, adds,
              modifies, improves, or updates this Website's consent with or
              without prior notice. Under no circumstance shall EZLinking be
              liable for any loss, damage, injury, liability, or expense
              incurred or suffered from the use of this Website, including,
              without limitation, any fault, error, omission, commission, delay,
              failure, interruption, deletion, alteration, disruption, cessation
              or incursion concerning such use by us, our affiliates or any
              third party. Under no circumstance shall EZLinking or any of its
              partners and affiliates be liable for any direct, indirect,
              consequential, accidental, or special damages, even if EZLinking
              has been advised against the risk or possibility of such damages.
              The User agrees that EZLinking will not be liable for any conduct
              or behaviour of the User arising from the use of this Website. As
              a result, the use of this Website and all or any of its content is
              at the User's sole risk. In no event shall EZLinking, nor any of
              its officers, directors, employees, and affiliates, be liable for
              any loss, injury, or damage arising out of your use of this
              Website, whether, under contract, tort, or otherwise, and
              EZLinking, including its officers, directors, employees, and
              affiliates shall not be liable for any indirect, consequential or
              special liability arising out of your use of this Website.
              <br />
              <br />
              <h3>Indemnification</h3>

              As a condition for the use of this Website, the User agrees to
              indemnify EZLinking and its affiliates to the fullest extent, from
              and against all actions, claims, liabilities, losses, damages,
              costs, demands, and expenses (including reasonable attorney's
              fees) arising out of the User's use of this Website, including
              without limitation, any claim related to the breach of any of the
              provisions of these Terms and Conditions. If dissatisfied with any
              or all of the content on this Website or any or all of its Terms
              and Conditions, the User may discontinue using this Website.
              <br />
              <br />
              <h3>Termination</h3>

              The provisions of these Terms and Conditions shall remain in full
              force and effect while you use the EZLinkingor its services. Users
              may terminate their use by following the instructions for
              terminating user accounts in your account settings or by
              contacting us at help.ezlink@gmail.com. We reserve the right and
              sole discretion to, and without notice or liability, deny access
              to and use of the Website (including blocking specific IP
              addresses) to any user for any reason including but not limited to
              breach of any representation, warranty, or Agreement in these
              Terms or any applicable law or regulation. We also reserve the
              right, if, in our sole discretion, we determine that your use of
              the Website or its services is in breach of these Terms and
              Conditions or of any applicable law or regulation, to terminate
              your use of the Website and its services or delete your account
              and any or all of your content, without warning or prior notice.
              Suppose we terminate or suspend your account for any reason set
              out under this section. In that case, you are prohibited from
              registering and creating a new account under your name, a false
              identity, or the expression of a third party. In addition to
              terminating or suspending your account, EZLinking reserves the
              right to take appropriate legal action(s), including without
              limitation pursuing civil, criminal, and injunctive redress.
              <br />
              <br />
              <h3>General Provisions</h3>

              <h4>Language</h4>

              All correspondence made under this Agreement shall be in English.
              <br />
              <br />
              <h4>Governing Law & Jurisdiction</h4>

              The Terms and Conditions of this Website will be governed by and
              construed under the laws of the country or state in which
              EZLinking operates. You hereby unconditionally submit to the
              non-exclusive jurisdiction of the courts located in Fiji for the
              resolution of any disputes.
              <br />
              <br />
              <h4>Severability</h4>

              Suppose any of Term or Conditions is proven to be unenforceable or
              void under any applicable law. In that case, such shall not render
              the entirety of these Terms and Conditions unenforceable or
              invalid. As a result, any such provision shall be deleted without
              affecting the remaining provisions herein. The provisions of these
              Terms and Conditions that are unlawful, void, or unenforceable are
              deemed severable from these Terms and Conditions and do not affect
              any remaining provisions' validity and enforceability.
              <br />
              <br />
              <h4>Variation of Terms</h4>

              EZLinking reserves the right to revise these Terms at any time as
              it sees fit. By using EZLinking, you are expected to review such
              Terms regularly to ensure you comprehend all the Terms and
              Conditions regarding the use of this Website.
              <br />
              <br />
              <h4>Assignment</h4>

              EZLinking reserves the right to assign, transfer, and subcontract
              its rights and/or obligations under this Agreement without any
              prior notification or consent required. Users shall not be
              permitted to assign, transfer, or subcontract any of your rights
              and/or obligations under these Terms. Furthermore, a person who is
              not a party to these Terms and Conditions shall have no right to
              enforce any provision contained therein.
              <br />
              <br />
              <h4>Preservation of Immunities</h4>

              Nothing herein shall constitute a limitation upon the privileges
              and immunities of EZLinking, which are specifically reserved.
              <br />
              <br />
              <h4>Waiver</h4>

              Our failure to exercise any or all of these Terms and Conditions
              provisions at any point in time shall not operate as a waiver of
              such right or provision.
              <br />
              <br />
              <h3>Community Standards</h3>

              We are putting in the effort to make EZLinking a user-friendly and
              trusted platform where users from different backgrounds can use
              the website without having objections and problems. Below are
              community Standards which must be agreed upon to use the website.
              <br />

              If you come across EZLinking content that doesn't meet the
              criteria below, please report it.
              <br />
              <br />
              <h4>Adult content</h4>

              Adult content or links to adult content is strictly prohibited on
              EZLinking. If reported, the content will be removed without notice
              to the author of the content. The linked content must be legal in
              your region. Media on EZLinking such as your profile avatar must
              be suitable for all ages.
              <br />
              <br />

              <h4>Hate Speech</h4>

              EZLinking does not harm, incite hatred or violence or discriminate
              against individuals or groups based on race, colour, national
              origin, religious belief, gender, sexual orientation or age. Nor
              is it a platform that should be used to glorify perpetrators of
              hate, violence and discrimination.
              <br />
              <br />
              <h4>Political content</h4>

              Do not use EZLinking to interfere with your local/national
              election process. Do not post content containing political views
              and government materials of any sort.
              <br />
              <br />

              <h4>Illegal goods and services</h4>

              Do not use this platform to sell or promote the sale of goods and
              materials, activity and behaviour which is considered or ruled
              illegal in your country and region.
              <br />
              <br />
              <h4>Fraud and Spam</h4>

              Do not use EZLinking to spam or scam your visitors. This includes
              links to external websites that may collect personal information
              for malicious purposes.
              <br />
              <br />
              <h4>Actions</h4>

              Do not use EZLinking to target, threaten, harass, intimidate or
              intimidate anyone. Do not use EZLinking to promote, advertise,
              glorify or sponsor extremist groups or their activities. Do not
              use EZLinking to cause child abuse in any way. Do not use
              EZLinking to post or promote content showing self-harm, suicide,
              abuse and violence.
              <br />
              <br />

              <h4>False Information</h4>

              Do not post misleading, false and incorrect information on
              EZLinking
              <br />
              <br />
              <h4>Entire Agreement</h4>

              These Terms and Conditions, including any legal notices and
              disclaimers on this Website, constitute the entire Agreement
              between EZLinking and you concerning your use of this Website.
              Ultimately, this Agreement supersedes all prior agreements and
              understandings concerning the same.
              <br />
              <br />
              <h4>Contact us</h4>

              To resolve any complaint or clarification regarding the use of
              this Website or its services or receive information concerning
              that, please contact us at help.ezlink@gmail.com.<br />
            </div>
          </v-card-text>

          <v-card-actions>
            <v-flex justify-center>
              <v-btn dark @click="TermsDialog = false">Agree</v-btn>
            </v-flex>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import NavgationBar from "../components/NavBar.vue";
import firebase from "firebase";
import { db } from "@/main";

export default {
  name: "SignUp",

  components: {
    NavgationBar,
  },
  data: function () {
    return {
      passwordMatch: false,
      btnDisable: true,
      TermAccepted: false,
      TermsDialog: false,
      userAtName: "",
      errorAtName: false,
      errorEmail: false,
      checkAtNameData: "",
      checkEmailData: "",
      email: "",
      password: "",
      password2: "",
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      result: "",
      rules4: [],
      userEmail: "",
      rules3: [],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
    };
  },
  computed: {
    passwordMatchCheck() {
      {
        if (this.password != this.password2) {
          this.result = "Passwords do not match";
        } else if (this.password == this.password2) {
          this.result = true;
        }
      }
      return this.result;
    },
  },
  methods: {
    checkEmail(input) {
      // //console.log("Checking", input);
      db.collection("users")
        .where("email", "==", input)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // console.log("doc.data()", doc.data().userAtName);
            this.checkAtNameData = doc.data().email;
            // doc.data() is never undefined for query doc snapshots
            this.rules4 = [
              "Entered Email address already exist in the system.",
            ];
            this.errorEmail = true;
          });
        })
        .catch((error) => {
          //console.log("Error getting documents: ", error);
        });
      if (this.checkEmailData != input) {
        this.rules4 = [];
        this.errorEmail = false;
      }
    },
    checkAtName(input) {
      // //console.log("Checking", input);
      db.collection("users")
        .where("userAtName", "==", input)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            console.log("doc.data()", doc.data().userAtName);
            this.checkAtNameData = doc.data().userAtName;
            // doc.data() is never undefined for query doc snapshots
            this.rules3 = ["Entered User Name is not available. Try again."];
            this.errorAtName = true;
          });
        })
        .catch((error) => {
          //console.log("Error getting documents: ", error);
        });
      if (this.checkAtNameData != input) {
        this.rules3 = [];
        this.errorAtName = false;
      }
    },

    continueSignup(e) {
      if (this.password.length < 8) {
        alert("Less than 8 characters in your password.");
        return;
      }
      if (this.password != this.password2) {
        alert("Your passwords do not match. Try again.");
        return;
      }

      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then((cred) => {
          return (
            db.collection("users").doc(cred.user.uid).set({
              backgroundColour: "#FBFBFBFF",
              backgroundColourAboutMe: "#B3B3B3FF",
              colourIconLink: "#000000FF",
              email: this.email,
              linkCounter: 0,
              links: [],
              textColourInfo: "#FFFFFFFF",
              textColourName: "#151515FF",
              textColourUserName: "#101010FF",
              userAtName: this.userAtName,
              userBio: "",
              userName: "",
              userPhotoURL: "",
              userType: "free",
            }),
            db
              .collection("stats")
              .doc("A1ZgOYn17QNw4dM5UMKV")
              .update({
                numberOfFreeUsers: firebase.firestore.FieldValue.increment(1),
                numberOfUsers: firebase.firestore.FieldValue.increment(1),
              }),
            cred.user
              .sendEmailVerification()
              .then(function () {
                alert(
                  "Please login into your email account and verify your email address by clicking on the link received. Check the spam folder if the email does not appear in the inbox."
                );
                // Email sent.
              })
              .then()
              .catch(function (error) {
                // An error happened.
                console.log(error);
              })
          );
        })
        .then(
          firebase
            .auth()
            .signOut()
            .then(() => {
              // alert(
              // "Please login into your mail and verify your email by clicking the link provided and login in again."
              //);
              this.$router.push("/Login");
            }),
          (err) => {
            alert(err.message);
            this.$router.push("/SignUp");
          }
        );
      e.preventDefault();
    },
  },
};
</script>
