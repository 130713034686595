<template>
  <div>
    <v-app-bar
      flat
      color="white"
      style="padding-top: 5px; padding-bottom: 30px"
      class="hidden-sm-and-down"
    >
      <v-app-bar-nav-icon
        color="black"
        @click.stop="drawer = !drawer"
        style="margin-top: -15px"
      />
      <v-spacer></v-spacer> <v-spacer></v-spacer>
      <v-row
        ><v-col md="3"
          ><v-img
            style="width: 70px; height: 70px; margin-top: 0px"
            src="../../public/E/1.png"
            lazy-src="../../public/E/1.png"
          />
        </v-col>
        <v-col md="9"
          ><div
            class="navTitle1"
            style="color: black; font-size: 60px; margin-top: -10px"
          >
            EZ LINKING
          </div>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
    </v-app-bar>
    <br />
    <v-app-bar
      flat
      color="white"
      style="padding-top: 0px; margin-left: -10px"
      class="hidden-md-and-up"
    >
      <v-app-bar-nav-icon
        color="black"
        @click.stop="drawer = !drawer"
        style="margin-top: -20px"
      />
      <v-spacer></v-spacer> <v-spacer></v-spacer>
      <v-row
        ><v-col sm="3"
          ><v-img
            style="width: 50px; height: 50px; margin-top: -10px"
            src="../../public/E/1.png"
            lazy-src="../../public/E/1.png"
          />
        </v-col>
        <v-col sm="7"
          ><div class="navTitle2" style="color: black; margin-top: -15px">
            EZLINK
          </div>
        </v-col>
      </v-row>
      <v-spacer></v-spacer> <v-spacer></v-spacer>
    </v-app-bar>
    <v-navigation-drawer
      style="position: fixed; width: 140px; padding-top: 10px"
      v-model="drawer"
      absolute
      left
      temporary
    >
      <v-list nav dense style="text-align: left; margin-left: 10px">
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          color="black"
        ></v-app-bar-nav-icon>
        <v-list-item-group v-model="group">
          <v-list-item v-if="$store.state.isLoggedIn == false">
            <v-list-item-title v-on:click.prevent="goTO('/')"
              ><v-icon color="black">mdi-home</v-icon> Home</v-list-item-title
            >
          </v-list-item>
          <v-list-item v-if="$store.state.isLoggedIn == true">
            <v-list-item-title
              v-on:click.prevent="
                goTO('/' + $store.state.userAtName + '/Profile')
              "
              ><v-icon color="black">mdi-card-account-details</v-icon>
              Profile</v-list-item-title
            >
          </v-list-item>
          <v-list-item v-if="$store.state.isLoggedIn == true">
            <v-list-item-title
              v-on:click.prevent="goToInterface('/' + $store.state.userAtName)"
              ><v-icon color="black">mdi-cellphone-screenshot</v-icon>
              Interface</v-list-item-title
            >
          </v-list-item>
          <v-list-item v-if="$store.state.isLoggedIn == true">
            <v-list-item-title
              v-on:click.prevent="
                goTO('/' + $store.state.userAtName + '/Admin')
              "
              ><v-icon color="black">mdi-account-settings</v-icon>
              Admin</v-list-item-title
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-title v-on:click.prevent="goTO('/AboutUs')"
              ><v-icon color="black">mdi-information</v-icon> About
              Us</v-list-item-title
            >
          </v-list-item>

          <v-list-item>
            <v-list-item-title v-on:click.prevent="goTO('/ContactUs')"
              ><v-icon color="black">mdi-contacts</v-icon>
              Contact</v-list-item-title
            >
          </v-list-item>
          <v-list-item v-if="$store.state.isLoggedIn == false">
            <v-list-item-title v-on:click.prevent="goTO('/Login')"
              ><v-icon color="black">mdi-login</v-icon> Login</v-list-item-title
            >
          </v-list-item>
          <v-list-item v-if="$store.state.isLoggedIn == false">
            <v-list-item-title v-on:click.prevent="goTO('/SignUp')"
              ><v-icon color="black">mdi-account-plus</v-icon> Sign
              Up</v-list-item-title
            >
          </v-list-item>

          <v-list-item v-if="$store.state.isLoggedIn == true">
            <v-list-item-title v-on:click.prevent="logout()"
              ><v-icon color="black">mdi-logout</v-icon> Logout
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import firebase from "firebase";
export default {
  data: () => ({
    drawer: false,
    group: null,
  }),
  methods: {
    goTO(link) {
      this.$router.push(link);
    },
    goToInterface(link) {
      window.open("https://ezlinking.net" + link, "_blank");
    },
    logout() {
      {
        firebase
          .auth()
          .signOut()
          .then(() => {
            {
              this.$store.commit("IsLoggedIn", false);
              this.$store.commit("UserAtName", "");
              this.$store.commit("UserID", "");
              this.$router.push(this.$route.query.redirect || "/");
            }
          });
      }
    },
  },

  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>
<style>
.navTitle1 {
  font-family: "Vidaloka", serif;
  text-align: center;
  font-size: 180%;
  width: 100%;
  text-align: center;
  color: black;
}
.navTitle2 {
  font-family: "Vidaloka", serif;
  text-align: center;
  font-size: 40px;
  width: 100%;
  text-align: center;
  color: black;
}

/* .navTitle {
  font-family: "Playfair Display SC", serif;
  text-align: center;
  font-size: 180%;
  width: 100%;
  text-align: center;
} */
</style>
