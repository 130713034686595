<template>
  <v-container fluid>
    <v-row class="text-center">
      <v-col cols="12" sm="5" md="6">
        <v-card>
          <span> <h2>My Profile</h2></span>
          <v-divider />
          <v-col cols="12" sm="12" md="12">
            <v-col>
              <v-text-field
                name="name"
                label="Your Name"
                outlined
                value=""
                v-model="user.userName"
                filled
                @change="editProfile = false"
              ></v-text-field>

              <v-text-field
                name="name"
                label="Your User Name"
                outlined
                value=""
                v-model="user.userAtName"
                filled
                :rules="rules3"
                @change="editProfile = false"
                @input="checkAtName(user.userAtName)"
              ></v-text-field>

              <v-textarea
                outlined
                name="bio"
                label="About You"
                v-model="user.userBio"
                value=""
                auto-grow
                :rules="rules1"
                counter
                filled
                @change="editProfile = false"
              ></v-textarea>

              <v-btn
                :disabled="editProfile"
                class="success"
                v-on:click.prevent="
                  updateProfile(user.userName, user.userAtName, user.userBio)
                "
              >
                Save Updates
              </v-btn>
            </v-col>
          </v-col>

          <v-divider />
          <v-col>
            <v-card flat>
              <v-btn class="primary" v-on:click.prevent="pickColours()">
                Choose Colours
              </v-btn>
            </v-card>
          </v-col>
        </v-card>

        <v-card
          style="padding-bottom: 5px; margin-bottom: -40px; margin-top: 10px"
        >
          <!-- <div>Public profile link: {{ user.profileURL }}</div> -->
          <div>Profile link: https://ezlinking.net/{{ $route.params.id }}</div>

          <v-btn small color="success" v-on:click.prevent="copyLink()"
            >Copy Link</v-btn
          >
        </v-card>
        <v-card flat>
          <div>
            <div class="container1">
              <div class="phone">
                <div class="speaker"></div>

                <div class="screen">
                  <v-card
                    flat
                    class="text-center"
                    tile
                    style="
                      height: 100%;
                      overflow-y: scroll;
                      overflow-y: scroll;
                      padding-top: 0px;
                    "
                    :color="user.backgroundColour"
                  >
                    <v-card
                      elevation="2"
                      tile
                      v-if="user.userPhotoURL != ''"
                      :color="user.backgroundColour"
                    >
                      <v-img
                        height="100%"
                        :src="user.userPhotoURL"
                        :lazy-src="user.userPhotoURL"
                        ><v-btn
                          x-small
                          fab
                          style="position: relative; top: 10px; left: 40%"
                        >
                          <v-icon>mdi-content-copy</v-icon></v-btn
                        >
                      </v-img>
                    </v-card>

                    <v-card
                      flat
                      :color="user.backgroundColour"
                      style="position: relative; top: 0px"
                    >
                      <v-row
                        style="
                          margin-left: 5px;
                          margin-right: 5px;
                          margin-top: 0px;
                        "
                      >
                        <v-col cols="12" sm="12" md="12">
                          <div class="userName">{{ user.userName }}</div>

                          <div class="userAtName">
                            {{ user.userAtName }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>

                    <v-card
                      :color="user.backgroundColourAboutMe"
                      style="
                        margin-left: 10px;
                        margin-right: 10px;
                        margin-top: 0px;
                      "
                    >
                      <div class="userBio">
                        {{ user.userBio }}
                      </div></v-card
                    >
                    <v-col cols="12" sm="12" md="6">
                      <v-row
                        style="margin-left: 0px; margin-top: 0px"
                        v-for="(link, index) in sortedList"
                        v-bind:key="link.id"
                      >
                        <v-btn
                          text
                          style="margin-bottom: 20px"
                          v-if="link.Active == true"
                        >
                          <v-icon
                            style="left: -20px"
                            :color="user.colourIconLink"
                          >
                            {{ link.mdi }}
                          </v-icon>

                          <div class="linkText">
                            {{ link.url }}
                          </div>
                        </v-btn>
                      </v-row></v-col
                    >
                  </v-card>
                </div>
              </div>
            </div>
          </div>
        </v-card>

        <!--  <v-card flat>
          <div>
            <div class="container1">
              <div class="phone">
                <div class="speaker"></div>

                <div class="screen">
                  <v-card
                    style="height: 100%; overflow-y: scroll; padding-top: 10px"
                    :color="user.backgroundColour"
                  >
                    <v-row style="margin-left: 5px; margin-right: 5px">
                      <v-col cols="2" sm="2" md="2">
                        <v-btn
                          style="margin-top: 0px; margin-left: 0px"
                          x-small
                          fab
                          color="white"
                          v-on:click.prevent="copyLink()"
                          ><v-icon>mdi-content-copy</v-icon></v-btn
                        >
                      </v-col>
                      <v-col cols="8" sm="8" md="8">
                        <v-avatar
                          style="margin-top: 10px"
                          v-if="user.userPhotoURL != ''"
                          class="elevation-12 mb-1"
                          size="90"
                        >
                          <v-img :src="user.userPhotoURL"></v-img>
                        </v-avatar>
                      </v-col>
                      <v-col cols="2" sm="2" md="2"></v-col>
                    </v-row>

                    <div class="userName">{{ user.userName }}</div>

                    <div class="userInfo">
                      {{ user.userAtName }}
                    </div>
                    <div class="userBio">
                      {{ user.userBio }}
                    </div>

                    <v-row
                      style="margin-left: 5px; margin-right: 5px"
                      v-for="(link, index) in sortedList"
                      v-bind:key="link.id"
                      :color="user.linkBackgroundColour"
                    >
                      <v-card
                        v-if="link.Active == true"
                        @click="openUrl(link.url)"
                        class="linkRow"
                        style="height: auto; width: 93%; padding-bottom: 3px"
                        :color="user.linkBackgroundColour"
                      >
                        <v-row
                          style="padding-top: 2px; padding-left: 5px"
                          :color="user.linkBackgroundColour"
                        >
                          <v-col cols="1" sm="1" md="1">
                            <v-icon
                              class="linkMDI"
                              :color="user.linkTextColour"
                            >
                              {{ link.mdi }}
                            </v-icon>
                          </v-col>
                          <v-col cols="10" sm="10" md="10">
                            <v-card flat :color="user.linkBackgroundColour">
                              <div class="linkTextColour">{{ link.name }}</div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-row>
                  </v-card>
                </div>
              </div>
            </div>
          </div>
        </v-card> -->
      </v-col>
      <v-col class="mb-5" cols="12" sm="7" md="6">
        <v-card color="white" tile width="auto" height="auto" elevation="2">
          <h2>My Avatar</h2>
          <v-divider></v-divider>
          <br />
          <!-- <v-avatar
            v-if="user.userPhotoURL != ''"
            class="elevation-12 mb-2"
            size="150"
          > -->
          <v-img
            v-if="user.userPhotoURL != ''"
            :src="user.userPhotoURL"
            width="auto"
          ></v-img>
          <!-- </v-avatar> -->
          <br />

          <v-btn
            class="mx-2"
            fab
            dark
            small
            color="error"
            v-if="user.userPhotoURL != ''"
            v-on:click.prevent="deleteAvatar()"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <!-- <v-avatar
            class="elevation-12 mb-2"
            v-if="user.userPhotoURL == ''"
            color="red"
            height="100px"
            width="100px"
          >
            <span class="white--text text-h2">A</span>
          </v-avatar> -->

          <div v-if="user.userPhotoURL == ''" class="white--black text-h4">
            Use input box below to upload your image
          </div>

          <v-file-input
            style="margin: 10px"
            :rules="rules2"
            accept="image/png, image/jpeg, image/bmp"
            placeholder="Upload your image"
            prepend-icon="mdi-camera"
            label="Upload your image"
            v-model="chosenFile"
            @change="uploadAvatar(chosenFile)"
          ></v-file-input>
          <v-divider></v-divider>
          <span> <h2>New Link</h2></span>
          <v-divider></v-divider>
          <v-row style="margin: 0px">
            <v-col cols="9" sm="9" md="9">
              <v-card flat color="white" width="100%" height="auto">
                <!-- <v-text-field
                  name="Link name"
                  v-model="linkName"
                  label="New link Name"
                  value=""
                  style="margin-top: -10px"
                ></v-text-field> -->
                <v-text-field
                  name="Link url"
                  v-model="linkURL"
                  label="Enter URL or Email or Phone"
                  value=""
                  style="margin-top: -10px"
                ></v-text-field>
                <v-select
                  :items="mdiIcon"
                  label="Pick an icon"
                  item-text="text"
                  item-value="mdi"
                  style="margin-top: -10px"
                  return-object
                  v-model="selectmdi"
                  @change="output(selectmdi)"
                >
                  <template v-slot:item="{ item }">
                    <v-icon style="margin-right: 5px" color="black">{{
                      item.mdi
                    }}</v-icon>
                    {{ item.text }}
                  </template>
                </v-select>
              </v-card>
            </v-col>
            <v-col cols="3" sm="3" md="3">
              <v-switch
                inset
                color="green"
                v-model="switch1"
                label="Link Active Status"
              ></v-switch>
              <v-btn color="primary" v-on:click.prevent="addData()">
                Add
              </v-btn>
            </v-col>
          </v-row>
          <v-divider /> <span> <h2>My Links</h2></span> <v-divider />

          <v-card style="height: 52vh; overflow-y: scroll">
            <v-row
              style="margin: 0px"
              v-for="(link, index) in sortedList"
              v-bind:key="link.id"
            >
              <v-card style="margin: 7px; width: 100%" color="#f2eee9">
                <v-row style="margin: 0px">
                  <v-col cols="10" sm="10" md="10">
                    <v-card flat color="#f2eee9">
                      <div class="divElement">
                        <span style="font-size: 18px">{{ link.name }}</span>
                        <br />
                        <span style="font-size: 14px">{{ link.url }}</span>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    <v-btn
                      icon
                      class="arrowBtnUp"
                      v-on:click.prevent="moveUp(index, sortedList)"
                    >
                      <v-icon color="black"
                        >mdi-arrow-up-drop-circle-outline</v-icon
                      >
                    </v-btn>

                    <br />
                    <v-btn
                      icon
                      class="arrowBtnDown"
                      v-on:click.prevent="moveDown(index, sortedList)"
                    >
                      <v-icon color="black"
                        >mdi-arrow-down-drop-circle-outline</v-icon
                      >
                    </v-btn>
                  </v-col>
                  <v-col>
                    <div style="text-align: right">
                      <v-row style="">
                        <v-col cols="12" sm="12" md="12">
                          <v-icon style="margin: 7px" color="success"
                            >mdi-eye</v-icon
                          >
                          {{ link.views }} View

                          <v-btn
                            style="margin-left: 20px; margin-right: 0px"
                            icon
                            color="primary"
                            v-on:click.prevent="openDialog(link)"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>

                          <v-btn
                            style="margin-left: 0px; margin-right: 0px"
                            icon
                            color="error"
                            v-on:click.prevent="deleteLink(link)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div></v-col
                  >
                </v-row>
              </v-card>
            </v-row>
          </v-card>
        </v-card>

        <!-- Dialog box -->
        <v-col cols="auto">
          <v-dialog
            v-model="dialog"
            transition="dialog-bottom-transition"
            max-width="600"
          >
            <v-card>
              <v-toolbar flat color="primary" dark>
                <h3>Edit Link</h3>
              </v-toolbar>
              <v-card-text>
                <v-col cols="12" sm="12" md="12">
                  <v-card flat color="white" width="100%" height="auto">
                    <v-text-field
                      name="Link name"
                      label="Link Name"
                      v-model="tempData.name"
                    ></v-text-field>
                    <v-text-field
                      name="Link url"
                      label="Link URL, Email or Phone Contact"
                      v-model="tempData.url"
                    ></v-text-field>
                  </v-card>
                </v-col>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-select
                      :items="mdiIcon"
                      label="Pick an icon"
                      item-text="text"
                      item-value="mdi"
                      style="margin-top: -10px"
                      v-model="tempData.mdi"
                    >
                      <template v-slot:item="{ item }">
                        <v-icon style="margin-right: 5px" color="black">{{
                          item.mdi
                        }}</v-icon>
                        {{ item.text }}
                      </template></v-select
                    >
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-switch
                      inset
                      style="margin: 0px"
                      value
                      color="green"
                      label="Link Active Status"
                      v-model="tempData.Active"
                    ></v-switch
                  ></v-col>

                  <v-col cols="12" sm="12" md="12">
                    <div style="text-align: right">
                      <v-icon large color="green">mdi-eye</v-icon>
                      <span style="font-size: 15px">
                        {{ tempData.views }} views</span
                      >
                      <v-btn
                        style="margin-left: 15px"
                        icon
                        large
                        color="primary"
                        v-on:click.prevent="saveData(tempData)"
                        @click="dialog = false"
                      >
                        <v-icon>mdi-content-save</v-icon>
                      </v-btn>

                      <v-btn
                        style="margin-left: 5px"
                        icon
                        large
                        color="error"
                        v-on:click.prevent="deleteLink(tempData)"
                        @click="dialog = false"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </v-col></v-row
                ></v-card-text
              >
              <v-card-actions class="justify-end">
                <v-btn text @click="dialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="errorDialog1" max-width="290">
            <v-card>
              <v-card-title class="text-h5" style="color: #ed5151">
                Opps
              </v-card-title>

              <v-card-text>
                Entered User Name is not available. Try again.
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="#ed5151" text @click="errorDialog1 = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- <v-dialog v-model="chooseColoursDialog" max-width="290">
            <v-card>
              <v-card-title class="text-h5"> Choose Colours </v-card-title>

              <v-card-text style="text-align: center">
                <v-btn
                  text
                  color="primary"
                  class="mb-2"
                  @click="backgroundColour = true"
                  >Background Colour</v-btn
                >
                <v-btn
                  text
                  color="primary"
                  class="mb-2"
                  @click="backgroundTextColour = true"
                  >Background Text Colour</v-btn
                >
                <v-btn
                  text
                  color="primary"
                  class="mb-2"
                  @click="linkBackgroundColour = true"
                  >Link Background Colour</v-btn
                >
                <v-btn
                  text
                  color="primary"
                  class="mb-2"
                  @click="linkTextColour = true"
                  >Link Text Colour</v-btn
                >
              </v-card-text>

              <v-card-actions>
                <v-row justify="end">
                  <v-btn
                    color="primary"
                    @click="chooseColoursDialog = false"
                    v-on:click.prevent="updateColour()"
                  >
                    Save Changes
                  </v-btn>
                  <v-btn
                    color="#ed5151"
                    text
                    @click="chooseColoursDialog = false"
                  >
                    Close
                  </v-btn> </v-row
                ><br />
                <br />
              </v-card-actions>
            </v-card>
          </v-dialog> -->
          <v-dialog v-model="chooseColoursDialog" max-width="290">
            <v-card>
              <v-card-title class="text-h5"> Choose Colours </v-card-title>

              <v-card-text style="text-align: center">
                <v-btn
                  text
                  color="primary"
                  class="mb-2"
                  @click="backgroundColour = true"
                  >Background Colour</v-btn
                >
                <v-btn
                  text
                  color="primary"
                  class="mb-2"
                  @click="textColourName = true"
                  >Name Text Colour</v-btn
                >

                <v-btn
                  text
                  color="primary"
                  class="mb-2"
                  @click="textColourUserName = true"
                  >Username Text Colour</v-btn
                >

                <v-btn
                  text
                  color="primary"
                  class="mb-2"
                  @click="textColourInfo = true"
                  >Bio Text Colour</v-btn
                >
                <v-btn
                  text
                  color="primary"
                  class="mb-2"
                  @click="backgroundColourAboutMe = true"
                  >Bio Background Colour</v-btn
                >

                <v-btn
                  text
                  color="primary"
                  class="mb-2"
                  @click="colourIconLink = true"
                  >Icon and Link Colour</v-btn
                >
              </v-card-text>

              <v-card-actions>
                <v-row justify="end">
                  <!-- <v-btn
                    color="primary"
                    @click="chooseColoursDialog = false"
                    v-on:click.prevent="updateColour()"
                  >
                    Save Changes
                  </v-btn> -->
                  <v-btn
                    color="#ed5151"
                    text
                    @click="chooseColoursDialog = false"
                  >
                    Close
                  </v-btn> </v-row
                ><br />
                <br />
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="backgroundColour" max-width="300">
            <v-card flat max-width="300">
              <v-color-picker
                mode="hexa"
                hide-mode-switch
                v-model="pickerBackgroundColour"
              ></v-color-picker>
              <v-card-actions>
                <v-btn
                  color="primary"
                  text
                  v-on:click.prevent="setBGC(pickerBackgroundColour)"
                >
                  Save
                </v-btn>

                <v-btn text color="red" @click="backgroundColour = false"
                  >Cancel</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="backgroundColourAboutMe" max-width="300">
            <v-card flat max-width="300">
              <v-color-picker
                mode="hexa"
                hide-mode-switch
                v-model="pickerBackgroundColourAboutMe"
              ></v-color-picker>
              <v-card-actions
                ><v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  v-on:click.prevent="setBGCAM(pickerBackgroundColourAboutMe)"
                >
                  Save
                </v-btn>

                <v-btn text color="red" @click="backgroundColourAboutMe = false"
                  >Cancel</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="textColourName" max-width="300">
            <v-card flat max-width="300">
              <v-color-picker
                mode="hexa"
                hide-mode-switch
                v-model="pickerTextColourName"
              ></v-color-picker>
              <v-card-actions>
                <v-btn
                  color="primary"
                  text
                  v-on:click.prevent="setTCN(pickerTextColourName)"
                >
                  Save
                </v-btn>

                <v-btn text color="red" @click="textColourName = false"
                  >Cancel</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="textColourUserName" max-width="300">
            <v-card flat max-width="300">
              <v-color-picker
                mode="hexa"
                hide-mode-switch
                v-model="pickerTextColourUserName"
              ></v-color-picker>
              <v-card-actions>
                <v-btn
                  color="primary"
                  text
                  v-on:click.prevent="setTCUN(pickerTextColourUserName)"
                >
                  Save
                </v-btn>

                <v-btn text color="red" @click="textColourUserName = false"
                  >Cancel</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="textColourInfo" max-width="300">
            <v-card flat max-width="300">
              <v-color-picker
                mode="hexa"
                hide-mode-switch
                v-model="pickerTextColourInfo"
              ></v-color-picker>
              <v-card-actions>
                <v-btn
                  color="primary"
                  text
                  v-on:click.prevent="setTCI(pickerTextColourInfo)"
                >
                  Save
                </v-btn>

                <v-btn text color="red" @click="textColourInfo = false"
                  >Cancel</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="colourIconLink" max-width="300">
            <v-card flat max-width="300">
              <v-color-picker
                mode="hexa"
                hide-mode-switch
                v-model="pickerColourIconLink"
              ></v-color-picker>
              <v-card-actions>
                <v-btn
                  color="primary"
                  text
                  v-on:click.prevent="setCIL(pickerColourIconLink)"
                >
                  Save
                </v-btn>

                <v-btn text color="red" @click="colourIconLink = false"
                  >Cancel</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- <v-dialog v-model="backgroundTextColour" max-width="300">
            <v-card flat max-width="300">
              <v-color-picker
                mode="hexa"
                hide-mode-switch
                v-model="pickerBackgroundTextColour"
              ></v-color-picker>
              <v-card-actions>
                <v-btn
                  color="primary"
                  text
                  v-on:click.prevent="setBGTC(pickerBackgroundTextColour)"
                >
                  Choose
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="linkBackgroundColour" max-width="300">
            <v-card flat max-width="300">
              <v-color-picker
                mode="hexa"
                hide-mode-switch
                v-model="pickerLinkbackgroundColour"
              ></v-color-picker>
              <v-card-actions>
                <v-btn
                  color="primary"
                  text
                  v-on:click.prevent="setLBGC(pickerLinkbackgroundColour)"
                >
                  Choose
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="linkTextColour" max-width="300">
            <v-card flat max-width="300">
              <v-color-picker
                mode="hexa"
                hide-mode-switch
                v-model="pickerLinkTextColour"
              ></v-color-picker>
              <v-card-actions>
                <v-btn
                  color="primary"
                  text
                  v-on:click.prevent="setLTC(pickerLinkTextColour)"
                >
                  Choose
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog> -->
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { db } from "@/main";
import firebase from "firebase/app";
import "firebase/storage";
import { fa } from "vuetify/lib/locale";
export default {
  name: "App",

  data: () => ({
    mdiIcon: [
      { text: "Beer", mdi: "mdi-beer" },
      { text: "Book", mdi: "mdi-book-open-page-variant" },
      { text: "Bunk Bed", mdi: "mdi-bunk-bed" },
      { text: "Camera Burst", mdi: " mdi-camera-burst" },
      { text: "Camera Image", mdi: " mdi-camera-image" },
      { text: "Card", mdi: "mdi-cards" },
      { text: "Chef Hat", mdi: "mdi-chef-hat" },
      { text: "Coffee", mdi: "mdi-coffee" },
      { text: "Clothing Store", mdi: "mdi-tshirt-crew" },
      { text: "Facebook", mdi: "mdi-facebook" },
      { text: "Food", mdi: "mdi-food" },
      { text: "Fork and Drink", mdi: "mdi-food-fork-drink" },
      { text: "Gym", mdi: "mdi-dumbbell" },
      { text: "Instagram", mdi: "mdi-instagram" },
      { text: "LinkedIn", mdi: "mdi-linkedin" },
      { text: "Email", mdi: "mdi-email" },
      { text: "Map Marker", mdi: "mdi-map-marker" },
      { text: "Menu", mdi: "mdi-menu" },
      { text: "Microsoft Sharepoint", mdi: "mdi-microsoft-sharepoint" },
      { text: "Microsoft Teams", mdi: "mdi-microsoft-teams" },
      { text: "Mobile", mdi: "mdi-cellphone" },
      { text: "Music Clef Treble Note", mdi: "mdi-music-clef-treble" },
      { text: "Phone", mdi: "mdi-phone" },
      { text: "Pinterest", mdi: "mdi-pinterest" },
      { text: "Tag", mdi: "mdi-tag" },
      { text: "Twitter", mdi: "mdi-twitter" },
      { text: "Shopping Cart", mdi: "mdi-cart" },
      { text: "Silverware", mdi: "mdi-silverware-variant" },
      { text: "Snapchat", mdi: "mdi-snapchat" },
      { text: "Sports", mdi: "mdi-soccer" },
      { text: "Spotify", mdi: "mdi-spotify" },
      { text: "Web", mdi: "mdi-web" },
      { text: "Youtube", mdi: "mdi-youtube" },
    ],
    selectmdi: "",
    user: "",
    userDocID: "",
    userAtName: "",
    name: "",
    bio: "",
    linkName: "",
    linkURL: "",
    dialog: false,
    editProfile: true,
    sortedList: [],
    tempData: [],
    EditingData: [],
    switch1: true,
    rules1: [(v) => v.length <= 100 || "Max 100 characters"],
    rules2: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        "Avatar size should be less than 2 MB!",
    ],
    rules3: [],
    checkAtNameData: "",
    errorAtName: false,
    errorDialog1: false,
    chooseColoursDialog: false,
    chosenFile: null,
    //backgroundColour: false,
    backgroundTextColour: false,
    linkBackgroundColour: false,
    linkTextColour: false,
    //pickerBackgroundColour: "#242f42",
    pickerBackgroundTextColour: "white",
    pickerLinkbackgroundColour: "white",
    pickerLinkTextColour: "#242f42",

    backgroundColour: false,
    backgroundColourAboutMe: false,
    textColourName: false,
    textColourUserName: false,
    textColourInfo: false,
    colourIconLink: false,

    pickerBackgroundColour: "white",
    pickerBackgroundColourAboutMe: "grey",
    pickerTextColourName: "black",
    pickerTextColourUserName: "black",
    pickerTextColourInfo: "black",
    pickerColourIconLink: "black",
  }),

  methods: {
    copyLink() {
      var textArea = document.createElement("textarea");

      textArea.value = "https://ezlinking.net/" + this.$route.params.id;

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        console.log("Copying text command was " + msg);
        alert("Link copied to clipboard.");
      } catch (err) {
        console.log("Oops, unable to copy");
      }

      document.body.removeChild(textArea);
    },
    // updateColour() {
    //   db.collection("users")
    //     .doc(this.userDocID)
    //     .update({
    //       // backgroundColour: this.pickerBackgroundColour,
    //       // backgroundTextColour: this.pickerBackgroundTextColour,
    //       // linkBackgroundColour: this.pickerLinkbackgroundColour,
    //       // linkTextColour: this.pickerLinkTextColour,
    //       backgroundColour: this.pickerBackgroundColour,
    //       backgroundColourAboutMe: this.pickerBackgroundColourAboutMe,
    //       textColourName: this.pickerTextColourName,
    //       textColourUserName: this.pickerTextColourUserName,
    //       textColourInfo: this.pickerTextColourInfo,
    //       colourIconLink: this.pickerColourIconLink,
    //     })
    //     .then((docRef) => {
    //       //console.log("Document Updated");
    //     })
    //     .catch((error) => {
    //       //console.error("Error adding document: ", error);
    //     });
    // },
    setBGC(colour) {
      this.pickerBackgroundColour = colour;
      this.backgroundColour = false;
      db.collection("users")
        .doc(this.userDocID)
        .update({
          backgroundColour: this.pickerBackgroundColour,
        })
        .then((docRef) => {
          //console.log("Document Updated");
        })
        .catch((error) => {
          //console.error("Error adding document: ", error);
        });
    },

    setBGCAM(colour) {
      this.pickerBackgroundColourAboutMe = colour;
      this.backgroundColourAboutMe = false;
      db.collection("users")
        .doc(this.userDocID)
        .update({
          backgroundColourAboutMe: this.pickerBackgroundColourAboutMe,
        })
        .then((docRef) => {
          //console.log("Document Updated");
        })
        .catch((error) => {
          //console.error("Error adding document: ", error);
        });
    },

    setTCN(colour) {
      this.pickerTextColourName = colour;
      this.textColourName = false;
      db.collection("users")
        .doc(this.userDocID)
        .update({
          textColourName: this.pickerTextColourName,
        })
        .then((docRef) => {
          //console.log("Document Updated");
        })
        .catch((error) => {
          //console.error("Error adding document: ", error);
        });
    },
    setTCUN(colour) {
      this.pickerTextColourUserName = colour;
      this.textColourUserName = false;
      db.collection("users")
        .doc(this.userDocID)
        .update({
          textColourUserName: this.pickerTextColourUserName,
        })
        .then((docRef) => {
          //console.log("Document Updated");
        })
        .catch((error) => {
          //console.error("Error adding document: ", error);
        });
    },
    setTCI(colour) {
      this.pickerTextColourInfo = colour;
      this.textColourInfo = false;
      db.collection("users")
        .doc(this.userDocID)
        .update({
          textColourInfo: this.pickerTextColourInfo,
        })
        .then((docRef) => {
          //console.log("Document Updated");
        })
        .catch((error) => {
          //console.error("Error adding document: ", error);
        });
    },
    setCIL(colour) {
      this.pickerColourIconLink = colour;
      this.colourIconLink = false;
      db.collection("users")
        .doc(this.userDocID)
        .update({
          colourIconLink: this.pickerColourIconLink,
        })
        .then((docRef) => {
          //console.log("Document Updated");
        })
        .catch((error) => {
          //console.error("Error adding document: ", error);
        });
    },
    // setBGTC(colour) {
    //   this.pickerBackgroundTextColour = colour;
    //   this.backgroundTextColour = false;
    // },
    // setLBGC(colour) {
    //   this.pickerLinkbackgroundColour = colour;
    //   this.linkBackgroundColour = false;
    // },
    // setLTC(colour) {
    //   this.pickerLinkTextColour = colour;
    //   this.linkTextColour = false;
    // },
    pickColours() {
      this.chooseColoursDialog = true;
    },
    output(value) {
      this.selectmdi = value.mdi;
    },
    openUrl(url) {
      // window.location.href = url;
      //window.location.assign(url);

      if (url.mdi == "mdi-phone") {
        console.log(url.url);
      } else {
        window.open(url, "_blank");
      }

      //
    },
    uploadAvatar(chosenFile) {
      // Create file metadata including the content type

      var metadata = {
        contentType: chosenFile.type,
      };

      const storageRef = firebase
        .storage()
        .ref(this.userDocID)
        .put(chosenFile, metadata);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          //console.log(error.message);
        },
        () => {
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            {
              db.collection("users")
                .doc(this.userDocID)
                .update({
                  userPhotoURL: url,
                })
                .then((docRef) => {
                  //console.log("Document Updated");
                })
                .catch((error) => {
                  //console.error("Error adding document: ", error);
                });

              this.chosenFile = null;
            }
          });
        }
      );
    },
    deleteAvatar() {
      // Create a reference to the file to delete
      const storageRef = firebase.storage().ref(this.userDocID);

      // Delete the file
      storageRef
        .delete()
        .then(() => {
          // File deleted successfully
          db.collection("users")
            .doc(this.userDocID)
            .update({
              userPhotoURL: "",
            })
            .then((docRef) => {
              //console.log("Document Updated");
            })
            .catch((error) => {
              //console.error("Error adding document: ", error);
            });
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
        });
    },
    checkAtName(input) {
      // //console.log("Checking", input);
      db.collection("users")
        .where("userAtName", "==", input)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            //   //console.log("doc.data()", doc.data().userAtName);
            this.checkAtNameData = doc.data().userAtName;
            // doc.data() is never undefined for query doc snapshots
            this.rules3 = ["Entered User Name is not available. Try again."];
            this.errorAtName = true;
            if (input === this.userAtName) {
              this.rules3 = [];
              this.errorAtName = false;
            }
          });
        })
        .catch((error) => {
          //console.log("Error getting documents: ", error);
        });
      if (this.checkAtNameData != input) {
        this.rules3 = [];
        this.errorAtName = false;
      }
    },
    updateProfile(userName, userAtName, userBio) {
      if (this.errorAtName) {
        this.errorDialog1 = true;
      } else if (!this.errorAtName) {
        db.collection("users")
          .doc(this.userDocID)
          .update({
            userName: userName,
            userAtName: userAtName,
            userBio: userBio,
          })
          .then((docRef) => {
            this.editProfile = true;
          })
          .catch((error) => {});
      }
    },
    openDialog(data) {
      this.tempData = { ...data };
      this.EditingData = { ...data };
      this.dialog = true;
    },
    saveData(data) {
      db.collection("users")
        .doc(this.userDocID)
        .update({
          links: firebase.firestore.FieldValue.arrayRemove({
            id: this.EditingData.id,
            name: this.EditingData.name,
            url: this.EditingData.url,
            views: this.EditingData.views,
            Active: this.EditingData.Active,
            mdi: this.EditingData.mdi,
          }),
        })
        .then((docRef) => {
          db.collection("users")
            .doc(this.userDocID)
            .update({
              links: firebase.firestore.FieldValue.arrayUnion({
                id: data.id,
                name: data.name,
                url: data.url,
                views: data.views,
                Active: data.Active,
                mdi: data.mdi,
              }),
            })
            .then((docRef) => {})
            .catch((error) => {});
        })
        .catch((error) => {});

      this.dialog = false;
    },
    addData() {
      db.collection("users")
        .doc(this.userDocID)
        .update({
          links: firebase.firestore.FieldValue.arrayUnion({
            id: this.user.linkCounter + 1,
            name: this.linkName,
            url: this.linkURL,
            views: 0,
            Active: this.switch1,
            mdi: this.selectmdi,
          }),
        })
        .then((docRef) => {
          db.collection("users")
            .doc(this.userDocID)
            .update({
              linkCounter: this.user.linkCounter + 1,
            });
          this.linkName = "";
          this.linkURL = "";
          this.selectmdi = "";
        })
        .catch((error) => {});
    },
    moveUp(index, sortedList) {
      var tempData1 = sortedList[index - 1];
      var tempData2 = sortedList[index];

      db.collection("users")
        .doc(this.userDocID)
        .update({
          links: firebase.firestore.FieldValue.arrayRemove({
            id: tempData1.id,
            name: tempData1.name,
            url: tempData1.url,
            views: tempData1.views,
            Active: tempData1.Active,
            mdi: tempData1.mdi,
          }),
        })
        .then((docRef) => {})
        .catch((error) => {});

      db.collection("users")
        .doc(this.userDocID)
        .update({
          links: firebase.firestore.FieldValue.arrayRemove({
            id: tempData2.id,
            name: tempData2.name,
            url: tempData2.url,
            views: tempData2.views,
            Active: tempData2.Active,
            mdi: tempData2.mdi,
          }),
        })
        .then((docRef) => {})
        .catch((error) => {});

      db.collection("users")
        .doc(this.userDocID)
        .update({
          links: firebase.firestore.FieldValue.arrayUnion({
            id: tempData1.id,
            name: tempData2.name,
            url: tempData2.url,
            views: tempData2.views,
            Active: tempData2.Active,
            mdi: tempData2.mdi,
          }),
        })
        .then((docRef) => {})
        .catch((error) => {});

      db.collection("users")
        .doc(this.userDocID)
        .update({
          links: firebase.firestore.FieldValue.arrayUnion({
            id: tempData2.id,
            name: tempData1.name,
            url: tempData1.url,
            views: tempData1.views,
            Active: tempData1.Active,
            mdi: tempData1.mdi,
          }),
        })
        .then((docRef) => {})
        .catch((error) => {});
    },
    moveDown(index, sortedList) {
      var tempData1 = sortedList[index + 1];
      var tempData2 = sortedList[index];

      db.collection("users")
        .doc(this.userDocID)
        .update({
          links: firebase.firestore.FieldValue.arrayRemove({
            id: tempData1.id,
            name: tempData1.name,
            url: tempData1.url,
            views: tempData1.views,
            Active: tempData1.Active,
            mdi: tempData1.mdi,
          }),
        })
        .then((docRef) => {
          //console.log("First Removed");
        })
        .catch((error) => {
          //console.error("Error adding document: ", error);
        });

      db.collection("users")
        .doc(this.userDocID)
        .update({
          links: firebase.firestore.FieldValue.arrayRemove({
            id: tempData2.id,
            name: tempData2.name,
            url: tempData2.url,
            views: tempData2.views,
            Active: tempData2.Active,
            mdi: tempData2.mdi,
          }),
        })
        .then((docRef) => {
          //console.log("First Removed");
        })
        .catch((error) => {
          //console.error("Error adding document: ", error);
        });

      db.collection("users")
        .doc(this.userDocID)
        .update({
          links: firebase.firestore.FieldValue.arrayUnion({
            id: tempData1.id,
            name: tempData2.name,
            url: tempData2.url,
            views: tempData2.views,
            Active: tempData2.Active,
            mdi: tempData2.mdi,
          }),
        })
        .then((docRef) => {
          //console.log("Document Updated");
        })
        .catch((error) => {
          //console.error("Error adding document: ", error);
        });

      db.collection("users")
        .doc(this.userDocID)
        .update({
          links: firebase.firestore.FieldValue.arrayUnion({
            id: tempData2.id,
            name: tempData1.name,
            url: tempData1.url,
            views: tempData1.views,
            Active: tempData1.Active,
            mdi: tempData1.mdi,
          }),
        })
        .then((docRef) => {
          //console.log("Document Updated");
        })
        .catch((error) => {
          //console.error("Error adding document: ", error);
        });
    },
    deleteLink(link) {
      db.collection("users")
        .doc(this.userDocID)
        .update({
          links: firebase.firestore.FieldValue.arrayRemove({
            id: link.id,
            name: link.name,
            url: link.url,
            views: link.views,
            Active: link.Active,
            mdi: link.mdi,
          }),
        })
        .then((docRef) => {
          //console.log("First Removed");
        })
        .catch((error) => {
          //console.error("Error adding document: ", error);
        });
    },
    getData(user) {
      db.collection("users")

        .doc(user)
        .onSnapshot((doc) => {
          this.user = doc.data();
          this.userAtName = doc.data().userAtName;
          this.userDocID = doc.id;

          this.sortedList = this.user.links.sort((a, b) => a.id - b.id);
        });
    },
  },
  computed: {},
  created() {
    // console.log(this.$route.params.id);

    db.collection("users")
      .where("userAtName", "==", this.$route.params.id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id);
          this.getData(doc.id);
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  },
  beforeMount() {
    this.rules1.length = null;
  },
};
</script>

<style scoped>
.divElement {
  text-align: left;
}
.arrowBtnUp {
  margin: -5px;
}
.arrowBtnDown {
  margin: -5px;
}

.v-btn {
  text-transform: unset !important;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: white;
}

.container1 {
  max-width: 600px;
  margin: 50px auto;
}
.up {
  top: 105px;
}

.down {
  top: 145px;
}
.phone {
  position: relative;
  background: #1e1e1e;
  height: 650px;
  width: 300px;
  border-radius: 25px;
  margin: 0 auto;
}

.speaker {
  position: absolute;
  background: #7a7a7a;
  height: 5px;
  width: 50px;
  border-radius: 15px;
  top: 20px;
  left: 42%;
}

.screen {
  position: absolute;
  overflow: hidden;
  background: v-bind("user.backgroundColour");
  height: 580px;
  width: 280px;
  top: 40px;
  left: 10px;
  background-size: cover;
  text-align: center;
}

.home-button {
  position: absolute;
  border: 1px solid #7a7a7a;
  height: 35px;
  width: 35px;
  border-radius: 25px;
  bottom: 12px;
  left: 50%;
  margin-left: -18px;
}
.userInfoName {
  color: v-bind("user.backgroundTextColour");
  font-size: 24px;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
}
.userAtInfo {
  color: v-bind("user.backgroundTextColour");
  font-size: 20px;
  margin-left: 5px;
  margin-right: 5px;
}

.link {
  text-align: center;
}
.linkRow {
  margin: 7px;
  width: 100%;
  cursor: pointer;
}

.linkContainer {
  background: v-bind("user.backgroundTextColour");
}

.linkTextContainer {
  margin-left: 5px;
  margin-right: 5px;
}

.userInfoBio {
  color: v-bind("user.backgroundTextColour");
  font-size: 15px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.linkText {
  margin-left: -20px;
  font-size: 12px;
  font-weight: normal;
  color: v-bind("user.colourIconLink");
}
.backgroundColour {
  color: v-bind("user.backgroundColour");
}
.userName {
  color: v-bind("user.textColourName");
  font-size: 25px;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
}

.userAtName {
  color: v-bind("user.textColourUserName");
  font-size: 18px;
  margin-left: 5px;
  margin-right: 5px;
}
.userBio {
  color: v-bind("user.textColourInfo");
  font-size: 15px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}
.userBioBackGround {
  color: v-bind("user.backgroundColourAboutMe");
  font-size: 12px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}
.colourIconLink {
  color: v-bind("user.colourIconLink");
  font-size: 14px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}
</style>
